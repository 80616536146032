// $(window).scroll(function() {
//     let $sections = $('._anchor');
//     $sections.each(function(i, el) {
//         let top = $(el).offset().top;
//         let bottom = top + $(el).height();
//         let scroll = $(window).scrollTop();
//         let idn = $(el).attr('id');
//         if (scroll > top && scroll < bottom) {
//             $('.text-nav__link._active').removeClass('_active');
//             $('.text-nav__link[href="#' + idn + '"]').addClass('_active');
//         }
//     });
// });

$(".text-nav__link").click(function () {
    $('.text-nav__link._active').removeClass('_active');
    $(this).addClass('_active');
})
