$(".lang__show").click(function () {
    $(this).parents(".lang").toggleClass("_open")
})

$(document).mouseup( function(e){
    let div = $( ".lang" );
    if ( !div.is(e.target)
        && div.has(e.target).length === 0 ) {
        if($(div).hasClass("_open")){
            $(div).removeClass("_open")
        }
    }
});

$("._toggle-menu").click(function () {
    $(".header").toggleClass("_open")
    $("body").toggleClass("fixed-body")
})

$(".header .menu-item-has-children>a").click(function (e) {
    if(window.innerWidth>992){
        return
    }
    e.preventDefault()
    $(this).parents(".menu-item-has-children").toggleClass("_open")
    $(this).siblings(".sub-menu").slideToggle()
})

