$("._tab").click(function() {
    let parentBlock = $(this).parents("._tabs-parent")
    let tabId = $(this).attr("data-tab")
    $(parentBlock).find("._tab").removeClass("_active")
    $(this).addClass("_active")
    $(parentBlock).find(".tab-content").removeClass("_active")
    $(parentBlock).find(`.${tabId}`).addClass("_active")
})

let telInputs = document.querySelectorAll(".tel-input input")
telInputs.forEach(input => {
    window.intlTelInput(input, {
        initialCountry: "ru",
        separateDialCode: true,
        // utilsScript: "js/utils.js"
    });
})



//
// $(".tel-input input").intlTelInput({
//     initialCountry: 'ru',
//     separateDialCode: true,
//     utilsScript: "js/utils.js"
// })

function setLabelPlace(){
    let fieldBlocks = document.querySelectorAll(".fg")

    fieldBlocks.forEach(block => {
        let field = block.querySelector("input,textarea")
        setLabelPosition(field)

        field.onchange = ()=> {
            setLabelPosition(field)
        }

        function setLabelPosition() {
            if(field.value !== ""){
                block.classList.add("_filled")
            } else{
                block.classList.remove("_filled")
            }
        }
    })
}


setLabelPlace()

$(".footer-nav__show").click(function () {
    if(window.innerWidth<=576){
        $(this).parents(".footer-nav").toggleClass("_active")
        $(this).siblings(".footer-nav__hidden").slideToggle()
    }
})
