$(".country-list__more").click(function () {
    $(".country-list").toggleClass("_open")
})

$(".qa-card__show").click(function () {
    let parent = $(this).parents(".qa-card")

    if($(parent).hasClass("_open")){
        $(".qa-card").removeClass("_open")
        $(".qa-card__hidden").slideUp()
    } else{
        $(".qa-card").removeClass("_open")
        $(".qa-card__hidden").slideUp()
        $(parent).addClass("_open")
        $(parent).find(".qa-card__hidden").slideDown()
    }
})


$(".country-nav__current").html(
    $(".country-nav__item._active").html()
)

$(".country-nav__show").click(function () {
    $(".country-nav").toggleClass("_open")
})

$(".country-nav__bg").click(function () {
    $(".country-nav").toggleClass("_open")
})

fixStickyMenu()
$(window).scroll(function() {
    fixStickyMenu()
})

function fixStickyMenu() {
    let countryInfoText = document.querySelector(".country-info__col2")
    if(!countryInfoText){
        return
    }


    console.log()
    if (countryInfoText.getBoundingClientRect().top <= 0) {
        $(".m-page").addClass("_fix-sticky-menu")
    } else {
        $(".m-page").removeClass("_fix-sticky-menu")
    }
}

$(window).on('load resize', function(){
    if ($(window).width() <= 992 && $(window).width() >= 576) {
        $('.media-info__items').insertAfter('.media-info__grid');
    } else{
        $('.media-info__items').insertAfter('.media-info .text');
    }
});
